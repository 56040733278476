/* eslint-disable */
import { DEGENOPOLY_ABI, DEGENOPOLY_NODE_ABI, DEGENOPOLY_NODE_FAMILY_ABI, DEGENOPOLY_NODE_MANAGER_ABI, DEGENOPOLY_PLAY_BOARD_ABI } from './abi';
import { sleep } from './utils';

const DEGENOPOLY_ADDRESS = process.env.REACT_APP_DEGENOPOLY
const DEGENOPOLY_NODE_MANAGER_ADDRESS = process.env.REACT_APP_DEGENOPOLY_NODE_MANAGER
const DEGENOPOLY_PLAY_BOARD_ADDRESS = process.env.REACT_APP_DEGENOPOLY_PLAY_BOARD

// NODE
const DEGENEOPOLY_NODE_INDIAN_JEET_CITY = process.env.REACT_APP_DEGENEOPOLY_NODE_INDIAN_JEET_CITY
const DEGENEOPOLY_NODE_HONEYPOT_LAND = process.env.REACT_APP_DEGENEOPOLY_NODE_HONEYPOT_LAND

const DEGENEOPOLY_NODE_ST_EXITSCAM = process.env.REACT_APP_DEGENEOPOLY_NODE_ST_EXITSCAM
const DEGENEOPOLY_NODE_RUG2RICHES = process.env.REACT_APP_DEGENEOPOLY_NODE_RUG2RICHES
const DEGENEOPOLY_NODE_SOFTRUG_BOULEVARD = process.env.REACT_APP_DEGENEOPOLY_NODE_SOFTRUG_BOULEVARD

const DEGENEOPOLY_NODE_SHITCOIN_PARADISE = process.env.REACT_APP_DEGENEOPOLY_NODE_SHITCOIN_PARADISE
const DEGENEOPOLY_NODE_PLEB_VCC = process.env.REACT_APP_DEGENEOPOLY_NODE_PLEB_VCC

const DEGENEOPOLY_NODE_PONZI_FARM = process.env.REACT_APP_DEGENEOPOLY_NODE_PONZI_FARM
const DEGENEOPOLY_NODE_SERS_CASTLE = process.env.REACT_APP_DEGENEOPOLY_NODE_SERS_CASTLE
const DEGENEOPOLY_NODE_APE_TERRITORY = process.env.REACT_APP_DEGENEOPOLY_NODE_APE_TERRITORY

const DEGENEOPOLY_NODE_ICO_GRAVEYARD = process.env.REACT_APP_DEGENEOPOLY_NODE_ICO_GRAVEYARD
const DEGENEOPOLY_NODE_DINOCOINS_CITY = process.env.REACT_APP_DEGENEOPOLY_NODE_DINOCOINS_CITY
const DEGENEOPOLY_NODE_MOONSHOT_STREET = process.env.REACT_APP_DEGENEOPOLY_NODE_MOONSHOT_STREET

const DEGENEOPOLY_NODE_LIQUIDATION_PARK = process.env.REACT_APP_DEGENEOPOLY_NODE_LIQUIDATION_PARK
const DEGENEOPOLY_NODE_GEMS_KINGDOM = process.env.REACT_APP_DEGENEOPOLY_NODE_GEMS_KINGDOM

const DEGENEOPOLY_NODE_GOBLIN_TOWN = process.env.REACT_APP_DEGENEOPOLY_NODE_GOBLIN_TOWN
const DEGENEOPOLY_NODE_THE_CITADEL = process.env.REACT_APP_DEGENEOPOLY_NODE_THE_CITADEL

// FAMILY
const DEGENEOPOLY_NODE_FAMILY_JEET = process.env.REACT_APP_DEGENEOPOLY_NODE_FAMILY_JEET
const DEGENEOPOLY_NODE_FAMILY_PLEB = process.env.REACT_APP_DEGENEOPOLY_NODE_FAMILY_PLEB
const DEGENEOPOLY_NODE_FAMILY_LURKER = process.env.REACT_APP_DEGENEOPOLY_NODE_FAMILY_LURKER
const DEGENEOPOLY_NODE_FAMILY_DIGGER = process.env.REACT_APP_DEGENEOPOLY_NODE_FAMILY_DIGGER
const DEGENEOPOLY_NODE_FAMILY_DEGEN = process.env.REACT_APP_DEGENEOPOLY_NODE_FAMILY_DEGEN
const DEGENEOPOLY_NODE_FAMILY_CALLER = process.env.REACT_APP_DEGENEOPOLY_NODE_FAMILY_CALLER
const DEGENEOPOLY_NODE_FAMILY_OG = process.env.REACT_APP_DEGENEOPOLY_NODE_FAMILY_OG

const ROLL_FEE = Number(process.env.REACT_APP_ROLL_FEE)

export const hasEnoughPoly = async (account, poly) => {
    try {
        let balance = await getPolyBalance(account)
        console.log('hasenoughpoly', balance, poly)
        if (balance >= poly) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.log(err.message);
        return false;
    }
}

export const getEthBalance = async (account) => {
    try {
        let balance = await window.web3.eth.getBalance(account)
        return Number(window.web3.utils.fromWei(balance, 'ether'))
    } catch (err) {
        console.log(err.message);
        return '0'
    }
}

export const getPolyBalance = async (account) => {
    try {
        let polyToken = new window.web3.eth.Contract(DEGENOPOLY_ABI, DEGENOPOLY_ADDRESS)
        let balance = await polyToken.methods.balanceOf(account).call()
        return Number(window.web3.utils.fromWei(balance, 'ether'))
    } catch (err) {
        console.log(err.message);
        return '0'
    }
}

export const getDailyRewards = async (account) => {
    while(true){
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_NODE_MANAGER_ABI, DEGENOPOLY_NODE_MANAGER_ADDRESS)
            let dailyRewards = await degenopoly.methods.dailyRewardOf(account).call()
            console.log('dailyRewards', dailyRewards)
            return window.web3.utils.fromWei(dailyRewards, 'ether')
        } catch (err) {
            console.log(err.message)
            // await sleep(30000)
            return 0
        }
    }
}

export const getPlayerInfo = async (account) => {
    while (true) {
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_ABI, DEGENOPOLY_ADDRESS)
            let player = await degenopoly.methods.getPlayerInfo(account).call()
            let startBonusRate = await degenopoly.methods.startRate().call()
            let bullishBonusRate = await degenopoly.methods.bullishRate().call()
            return { ...player, startBonusRate, bullishBonusRate }
        } catch (err) {
            console.log(err.message);
            await sleep(30000)
        }
    }
}

export const getClaimableRewards = async (account) => {
    while(true){
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_NODE_MANAGER_ABI, DEGENOPOLY_NODE_MANAGER_ADDRESS)
            let claimableRewards = await degenopoly.methods.claimableReward(account).call()
            console.log('claimable', claimableRewards)
            return window.web3.utils.fromWei(claimableRewards, 'ether')
        } catch (err) {
            console.log(err.message)
            await sleep(30000)
        }
    }
}

export const getDice = async (account) => {
    while(true){
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_PLAY_BOARD_ABI, DEGENOPOLY_PLAY_BOARD_ADDRESS)
            let dice = await degenopoly.methods.diceOf(account).call()
            console.log('dice', dice)
            return dice
        } catch (err) {
            console.log(err.message)
            await sleep(30000)
        }
    }
}

export const getPositionOf = async (account) => {
    while(true){
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_PLAY_BOARD_ABI, DEGENOPOLY_PLAY_BOARD_ADDRESS)
            let positionOf = await degenopoly.methods.positionOf(account).call()
            console.log('positionOf', positionOf)
            return positionOf
        } catch (err) {
            console.log(err.message)
            await sleep(30000)
        }
    }
}

export const getLastEventTypeOf = async (account) => {
    while(true){
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_PLAY_BOARD_ABI, DEGENOPOLY_PLAY_BOARD_ADDRESS)
            let lastEventTypeOf = await degenopoly.methods.getLastEventType(account).call()
            console.log('lastEventTypeOf', lastEventTypeOf)
            return lastEventTypeOf
        } catch (err) {
            console.log(err.message)
            await sleep(30000)
        }
    }
}

export const getMultiplierFor = async (account) => {
    while(true){
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_NODE_MANAGER_ABI, DEGENOPOLY_NODE_MANAGER_ADDRESS)
            let multiplier = await degenopoly.methods.getMultiplierFor(account).call()
            console.log('getMultiplierFor', multiplier)
            return multiplier
        } catch (err) {
            console.log(err.message)
            await sleep(30000)
        }
    }
}

export const mintedOf = async (account, pos) => {
    while(true){
        try {
            let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_PLAY_BOARD_ABI, DEGENOPOLY_PLAY_BOARD_ADDRESS)
            let mintedOf = await degenopoly.methods.mintableNode(account).call()
            console.log('mintedOf', mintedOf)
            return mintedOf
        } catch (err) {
            console.log(err.message)
            await sleep(30000)
        }
    }
}

export const rollDice = async (account) => {
    try {
        let polyToken = new window.web3.eth.Contract(DEGENOPOLY_ABI, DEGENOPOLY_ADDRESS)

        let allowance

        allowance = Number(window.web3.utils.fromWei(await polyToken.methods.allowance(account, DEGENOPOLY_PLAY_BOARD_ADDRESS).call()))
        console.log('allowance', allowance)
        
        if (allowance < ROLL_FEE) {
            let polyBalance = await getPolyBalance(account)
            console.log('polybalance', polyBalance)
            let res = await polyToken.methods.approve(DEGENOPOLY_PLAY_BOARD_ADDRESS, window.web3.utils.toWei(polyBalance.toString(), 'ether')).send({ from: account });
            console.log("approve", res.status);
        }

        let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_PLAY_BOARD_ABI, DEGENOPOLY_PLAY_BOARD_ADDRESS)
        let res = await degenopoly.methods.rollDice().send({ from: account })
        return res.status
    } catch (err) {
        console.log(err.message)
        return false
    }
}

export const moveTo = async (account, pos) => {
    try {
        let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_PLAY_BOARD_ABI, DEGENOPOLY_PLAY_BOARD_ADDRESS)
        let res = await degenopoly.methods.moveCase(pos).send({
            from: account
        })
        return res.status
    } catch (err) {
        console.log(err.message)
        return false
    }
}

export const getFreeNode = async (account, pos) => {
    try {
        let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_PLAY_BOARD_ABI, DEGENOPOLY_PLAY_BOARD_ADDRESS)
        let node_address

        console.log('pos', pos);

        if (pos === 1) {
            node_address = DEGENEOPOLY_NODE_INDIAN_JEET_CITY
        } else if (pos === 3) {
            node_address = DEGENEOPOLY_NODE_HONEYPOT_LAND
        } else if (pos === 4) {
            node_address = DEGENEOPOLY_NODE_ST_EXITSCAM
        } else if (pos === 5) {
            node_address = DEGENEOPOLY_NODE_RUG2RICHES
        } else if (pos === 6) {
            node_address = DEGENEOPOLY_NODE_SOFTRUG_BOULEVARD
        } else if (pos === 8) {
            node_address = DEGENEOPOLY_NODE_SHITCOIN_PARADISE
        } else if (pos === 9) {
            node_address = DEGENEOPOLY_NODE_PLEB_VCC
        } else if (pos === 10) {
            node_address = DEGENEOPOLY_NODE_PONZI_FARM
        } else if (pos === 11) {
            node_address = DEGENEOPOLY_NODE_SERS_CASTLE
        } else if (pos === 12) {
            node_address = DEGENEOPOLY_NODE_APE_TERRITORY
        } else if (pos === 14) {
            node_address = DEGENEOPOLY_NODE_ICO_GRAVEYARD
        } else if (pos === 15) {
            node_address = DEGENEOPOLY_NODE_DINOCOINS_CITY
        } else if (pos === 16) {
            node_address = DEGENEOPOLY_NODE_MOONSHOT_STREET
        } else if (pos === 18) {
            node_address = DEGENEOPOLY_NODE_LIQUIDATION_PARK
        } else if (pos === 20) {
            node_address = DEGENEOPOLY_NODE_GEMS_KINGDOM
        } else if (pos === 22) {
            node_address = DEGENEOPOLY_NODE_GOBLIN_TOWN
        } else if (pos === 23) {
            node_address = DEGENEOPOLY_NODE_THE_CITADEL
        }

        let res = await degenopoly.methods.getFreeNode(node_address).send({
            from: account
        })
        return res.status
    } catch (err) {
        console.log(err.message)
        return false
    }
}

export const claimRewards = async (account) => {
    try {
        let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_NODE_MANAGER_ABI, DEGENOPOLY_NODE_MANAGER_ADDRESS)
        let res = await degenopoly.methods.claimReward().send({ from: account })
        return res.status
    } catch (err) {
        console.log(err.message)
        return false
    }
}

export const getTokenIdsForFamily = async (account, family) => {
    try {
        let ret = [];
        let polyNFT;
        let balance;
        let res;

        if (family == "jeet") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_INDIAN_JEET_CITY)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_HONEYPOT_LAND)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
        } else if (family == "pleb") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_ST_EXITSCAM)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_RUG2RICHES)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SOFTRUG_BOULEVARD)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
        } else if (family == "lurker") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SHITCOIN_PARADISE)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_PLEB_VCC)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
        } else if (family == "digger") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_PONZI_FARM)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SERS_CASTLE)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_APE_TERRITORY)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
        } else if (family == "degen") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_ICO_GRAVEYARD)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_DINOCOINS_CITY)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_MOONSHOT_STREET)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
        } else if (family == "caller") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_LIQUIDATION_PARK)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_GEMS_KINGDOM)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
        } else if (family == "og") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_GOBLIN_TOWN)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
            
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_THE_CITADEL)
            balance = await polyNFT.methods.balanceOf(account).call()

            if (balance > 0) {
                res = await polyNFT.methods.tokenOfOwnerByIndex(account, 0).call();
                ret.push(Number(res));
            } else {
                return [];
            }
        }
        return ret;
    } catch (err) {
        console.log(err.message)
        return false
    }
}

export const mintNode = async (account, price, nft_case) => {
    try {
        let polyToken = new window.web3.eth.Contract(DEGENOPOLY_ABI, DEGENOPOLY_ADDRESS)

        let allowance

        allowance = Number(window.web3.utils.fromWei(await polyToken.methods.allowance(account, DEGENOPOLY_NODE_MANAGER_ADDRESS).call()))
        console.log('allowance', allowance)
        
        if (allowance < price) {
            let polyBalance = await getPolyBalance(account)
            console.log('polybalance', polyBalance)
            let res = await polyToken.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, window.web3.utils.toWei(polyBalance.toString(), 'ether')).send({ from: account });
            console.log("approve", res.status);
        }
        let node_address

        if (nft_case == "Indian Jeet City_nft") {
            node_address = DEGENEOPOLY_NODE_INDIAN_JEET_CITY
        } else if (nft_case == "Honeypot Land_nft") {
            node_address = DEGENEOPOLY_NODE_HONEYPOT_LAND
        } else if (nft_case == "St Exitscam_nft") {
            node_address = DEGENEOPOLY_NODE_ST_EXITSCAM
        } else if (nft_case == "Rug2Riches_nft") {
            node_address = DEGENEOPOLY_NODE_RUG2RICHES
        } else if (nft_case == "Softrug Boulevard_nft") {
            node_address = DEGENEOPOLY_NODE_SOFTRUG_BOULEVARD
        } else if (nft_case == "Shitcoin Paradise_nft") {
            node_address = DEGENEOPOLY_NODE_SHITCOIN_PARADISE
        } else if (nft_case == "Pleb VCC_nft") {
            node_address = DEGENEOPOLY_NODE_PLEB_VCC
        } else if (nft_case == "Ponzi Farm_nft") {
            node_address = DEGENEOPOLY_NODE_PONZI_FARM
        } else if (nft_case == "$er\'s Castle_nft") {
            node_address = DEGENEOPOLY_NODE_SERS_CASTLE
        } else if (nft_case == "Ape Territory_nft") {
            node_address = DEGENEOPOLY_NODE_APE_TERRITORY
        } else if (nft_case == "ICO Graveyard_nft") {
            node_address = DEGENEOPOLY_NODE_ICO_GRAVEYARD
        } else if (nft_case == "Dinocoins City_nft") {
            node_address = DEGENEOPOLY_NODE_DINOCOINS_CITY
        } else if (nft_case == "Moonshot Street_nft") {
            node_address = DEGENEOPOLY_NODE_MOONSHOT_STREET
        } else if (nft_case == "Liquidation Park_nft") {
            node_address = DEGENEOPOLY_NODE_LIQUIDATION_PARK
        } else if (nft_case == "Gems Kingdom_nft") {
            node_address = DEGENEOPOLY_NODE_GEMS_KINGDOM
        } else if (nft_case == "Goblin Town_nft") {
            node_address = DEGENEOPOLY_NODE_GOBLIN_TOWN
        } else if (nft_case == "The Citadel_nft") {
            node_address = DEGENEOPOLY_NODE_THE_CITADEL
        }

        let degenopoly = new window.web3.eth.Contract(DEGENOPOLY_NODE_MANAGER_ABI, DEGENOPOLY_NODE_MANAGER_ADDRESS)
        let res = await degenopoly.methods.purchaseNode(node_address).send({ from: account })
        return res.status
    } catch (err) {
        console.log(err.message)
        return false
    }
}

export const mintNodeFamilyNFT = async (account, family, token_ids) => {
    try {
        let res
        let polyManager = new window.web3.eth.Contract(DEGENOPOLY_NODE_MANAGER_ABI, DEGENOPOLY_NODE_MANAGER_ADDRESS)    
        let polyNFT

        if (family == "jeet") {        
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_INDIAN_JEET_CITY)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[0]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_HONEYPOT_LAND)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[1]).send({ from: account });
            console.log("approve", res.status);

            res = await polyManager.methods.purchaseNodeFamily(DEGENEOPOLY_NODE_FAMILY_JEET, token_ids).send({ from: account });
        } else if (family == "pleb") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_ST_EXITSCAM)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[0]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_RUG2RICHES)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[1]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SOFTRUG_BOULEVARD)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[2]).send({ from: account });
            console.log("approve", res.status);

            res = await polyManager.methods.purchaseNodeFamily(DEGENEOPOLY_NODE_FAMILY_PLEB, token_ids).send({ from: account });
        } else if (family == "lurker") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SHITCOIN_PARADISE)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[0]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_PLEB_VCC)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[1]).send({ from: account });
            console.log("approve", res.status);

            res = await polyManager.methods.purchaseNodeFamily(DEGENEOPOLY_NODE_FAMILY_LURKER, token_ids).send({ from: account });
        } else if (family == "digger") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_PONZI_FARM)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[0]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SERS_CASTLE)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[1]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_APE_TERRITORY)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[2]).send({ from: account });
            console.log("approve", res.status);
            
            res = await polyManager.methods.purchaseNodeFamily(DEGENEOPOLY_NODE_FAMILY_DIGGER, token_ids).send({ from: account });
        } else if (family == "degen") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_ICO_GRAVEYARD)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[0]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_DINOCOINS_CITY)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[1]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_MOONSHOT_STREET)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[2]).send({ from: account });
            console.log("approve", res.status);
            
            res = await polyManager.methods.purchaseNodeFamily(DEGENEOPOLY_NODE_FAMILY_DEGEN, token_ids).send({ from: account });
        } else if (family == "caller") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_LIQUIDATION_PARK)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[0]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_GEMS_KINGDOM)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[1]).send({ from: account });
            console.log("approve", res.status);

            res = await polyManager.methods.purchaseNodeFamily(DEGENEOPOLY_NODE_FAMILY_CALLER, token_ids).send({ from: account });
        } else if (family == "og") {
            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_GOBLIN_TOWN)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[0]).send({ from: account });
            console.log("approve", res.status);

            polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_THE_CITADEL)
            res = await polyNFT.methods.approve(DEGENOPOLY_NODE_MANAGER_ADDRESS, token_ids[1]).send({ from: account });
            console.log("approve", res.status);

            res = await polyManager.methods.purchaseNodeFamily(DEGENEOPOLY_NODE_FAMILY_OG, token_ids).send({ from: account });
        }
        return res.status
    } catch (err) {
        console.log(err.message)
        return false
    }
}

export const getNFTs = async (account) => {
    try {
        let ret = {};

        let polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_INDIAN_JEET_CITY)
        let balance = await polyNFT.methods.balanceOf(account).call()
        ret.indian_jeet_city = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_HONEYPOT_LAND)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.honeypot_land = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_ST_EXITSCAM)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.st_existscam = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_RUG2RICHES)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.rug2riches = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SOFTRUG_BOULEVARD)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.softrug_boulevard = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SHITCOIN_PARADISE)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.shitcoin_paradise = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_PLEB_VCC)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.pleb_vcc = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_PONZI_FARM)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.ponzi_farm = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_SERS_CASTLE)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.sers_castle = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_APE_TERRITORY)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.ape_territory = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_ICO_GRAVEYARD)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.ico_graveyard = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_DINOCOINS_CITY)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.dinocoins_city = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_MOONSHOT_STREET)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.moonshot_street = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_LIQUIDATION_PARK)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.liquidation_park = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_GEMS_KINGDOM)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.gems_kingdom = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_GOBLIN_TOWN)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.goblin_town = balance;

        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_ABI, DEGENEOPOLY_NODE_THE_CITADEL)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.the_citadel = balance;

        // FAMILY
        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_FAMILY_ABI, DEGENEOPOLY_NODE_FAMILY_JEET)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.family_jeet = balance;
        
        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_FAMILY_ABI, DEGENEOPOLY_NODE_FAMILY_PLEB)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.family_pleb = balance;
        
        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_FAMILY_ABI, DEGENEOPOLY_NODE_FAMILY_LURKER)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.family_lurker = balance;
        
        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_FAMILY_ABI, DEGENEOPOLY_NODE_FAMILY_DIGGER)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.family_digger = balance;
        
        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_FAMILY_ABI, DEGENEOPOLY_NODE_FAMILY_DEGEN)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.family_degen = balance;
        
        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_FAMILY_ABI, DEGENEOPOLY_NODE_FAMILY_CALLER)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.family_caller = balance;
        
        polyNFT = new window.web3.eth.Contract(DEGENOPOLY_NODE_FAMILY_ABI, DEGENEOPOLY_NODE_FAMILY_OG)
        balance = await polyNFT.methods.balanceOf(account).call()
        ret.family_og = balance;

        return ret;
    } catch (err) {
        console.log(err.message)
        return {}
    }
}

export const isBigger = (x, y) => {
    x = x || "0";
    y = y || "0";
    if (x.length > y.length) y = "0".repeat(x.length - y.length) + y;
    else if (y.length > x.length) x = "0".repeat(y.length - x.length) + x;

    for (let i = 0; i < x.length; i++) {
        if (x[i] < y[i]) return -1;
        if (x[i] > y[i]) return 1;
    }
    return 0;
}


export const shortAddress = (address) => {
    if (address !== "" || address !== undefined) {
        let lowCase = address.toLowerCase();
        return "0x" + lowCase.charAt(2).toUpperCase() + lowCase.substr(3, 3) + "..." + lowCase.substr(-4);
    }
    return address;
}

export const shortBalance = (val) => {
    let num = parseFloat(val).toFixed(2)
    return num
}