export const SET_STATUS = 'SET_STATUS';
export const SET_WALLET = 'SET_WALLET';
export const SET_MODAL = 'SET_MODAL'
export const SET_CASE_MODAL = 'SET_CASE_MODAL'
export const SET_DICE = 'SET_DICE'
export const SET_PLAYER_INFO = 'SET_PLAYER_INFO'
export const SET_ROLLABLE = 'SET_ROLLABLE'

//////////  TEST
export const SET_ROLL_FREE = 'SET_ROLL_FREE'
